import React from "react";
import {ChannelType} from "../ai";
import {SectionCardPage, SectionProps} from "./common";
import {ReactComponent as channelsSvg} from "../illustrations/undraw_mobile_marketing_iqbr.svg";


const channelMapping: { [K in ChannelType]: string } = {
    gifts: 'Werbegeschenke & Give-aways',
    newsletter: 'Newsletter',
    other: 'Sonstiges',
    print: 'Out-of-Home Werbung',
    socialmedia: 'Social Media',
    website: 'Eigene Website',
    equipment: 'Geschäftsausstattung',
};

const channelsInOrder: ChannelType[] = [
    'website', 'socialmedia', 'newsletter', 'gifts', 'print', 'other', // 'equipment'
];

export const ChannelsSection: React.FunctionComponent<SectionProps> = ({tree, update, cancel, commit}) => {
    return (
        <SectionCardPage title="Kanäle" subtitle="Auf welche Art und Weise betreiben Sie aktuell ihr Marketing?"
                         illustration={channelsSvg}
                         cancel={cancel}
                         commit={commit}
        >

            <div className="flex flex-col space-y-4 px-4 pb-8">
                {channelsInOrder.map(channel => (
                    <label key={channel} className="space-x-2 tracking-wide text-gray-800 flex items-center">
                        <input
                            checked={tree.channels[channel]}
                            onChange={(e) => update({
                                ...tree,
                                channels: {
                                    ...tree.channels,
                                    [channel]: e.target.checked,
                                },
                            })}
                            type="checkbox"
                            className="form-checkbox text-brand transition duration-150 ease-in-out border-gray-600 border-2"
                        />
                        <span>{channelMapping[channel]}</span>
                    </label>
                ))}
            </div>
        </SectionCardPage>
    );

};