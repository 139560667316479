import * as React from "react";

type Props = {
    className?: string;
}

export const Check = ({className}: Props) => (
    <svg
        className={`fill-current ${className}`.trim()}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
    >
        <path
            d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724c-.465-.613-.344-1.486.27-1.951.615-.467 1.488-.344 1.953.27l2.351 3.104 5.911-9.492c.407-.652 1.267-.852 1.921-.445.653.406.854 1.266.446 1.92L9.478 16.34c-.242.391-.661.635-1.12.656-.022.002-.042.002-.064.002z"/>
    </svg>
);