import React from "react";
import cx from "classnames";
import {ChannelType} from "../ai";
import {SectionCardPage, SectionProps} from "./common";
import {ReactComponent as measuresSvg} from "../illustrations/undraw_mobile_testing_reah.svg";
import '@rmwc/slider/styles';
import {Slider} from "@rmwc/slider";
import {StarRating} from "../components/StarRating";


const channelMapping: { [K in ChannelType]: string } = {
    gifts: 'Werbegeschenke & Give-aways',
    newsletter: 'Newsletter',
    other: 'Sonstiges',
    print: 'Out-of-Home Werbung',
    socialmedia: 'Social Media',
    website: 'Eigene Website',
    equipment: 'Geschäftsausstattung',
};

const channelsInOrder: ChannelType[] = [
    'website', 'socialmedia', 'newsletter', 'gifts', 'print', 'equipment',// 'other'
];

export const MeasuresSection: React.FunctionComponent<SectionProps> = ({tree, update: _update, cancel, commit}) => {
    const update = (measures: Partial<typeof tree.measures>) => _update({
        ...tree,
        measures: {
            ...tree.measures,
            ...measures,
        },
    });
    return (
        <SectionCardPage title="Aktuelle Maßnahmen" subtitle="Welche Maßnahmen verfolgen Sie derzeit mit welcher Intensität?"
                         illustration={measuresSvg}
                         cancel={cancel}
                         commit={commit}
        >

            <div className="px-4">
                <div className="hidden sm:grid grid-cols-2 sm:grid-cols-4 col-gap-6 row-gap-2 font-bold tracking-wide">
                    <div className="col-span-2">Maßnahme</div>
                    <div className="text-center">Intensität</div>
                    <div>Zufriedenheit</div>
                </div>
                {channelsInOrder.map(channel => (
                    <div key={channel} className="grid grid-cols-2 sm:grid-cols-4 col-gap-6 row-gap-1 py-3 sm:py-0">
                        <div className="col-span-2 flex items-center">
                            <label key={channel} className="space-x-2 tracking-wide text-gray-800 flex items-center">
                                <input
                                    checked={tree.measures[channel] !== null}
                                    onChange={(e) => update({
                                        [channel]: e.target.checked ? { intensity: 3, happiness: null } : null,
                                    })}
                                    type="checkbox"
                                    className="form-checkbox text-brand transition duration-150 ease-in-out border-gray-600 border-2"
                                />
                                <span>{channelMapping[channel]}</span>
                            </label>
                        </div>

                        <div>
                            <span className="sm:hidden font-bold">Intensität</span>
                            <Slider
                                className={cx({
                                    'text-brand': (tree.measures[channel]?.intensity ?? null) !== null,
                                    'text-black': (tree.measures[channel]?.intensity ?? null) === null,
                                })}
                                value={tree.measures[channel]?.intensity ?? 3}
                                min={1}
                                max={5}
                                step={1}
                                discrete
                                onChange={evt => update({
                                    [channel]: {
                                        intensity: evt.detail.value,
                                        happiness: tree.measures[channel]?.happiness ?? null,
                                    },
                                })}
                                onInput={() => {}}
                            />
                        </div>

                        <div className="flex flex-col sm:justify-center">
                            <span className="sm:hidden font-bold mb-4">Zufriedenheit</span>
                            <StarRating
                                value={tree.measures[channel]?.happiness ?? 0}
                                onChange={rating => update({
                                    [channel]: {
                                        intensity: tree.measures[channel]?.intensity ?? 3,
                                        happiness: tree.measures[channel]?.happiness === rating ? null : rating,
                                    }
                                })}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </SectionCardPage>
    );

};