import React, {ReactNode, useEffect} from "react";
import cx from "classnames";
import {Link} from "react-router-dom";
import {Close} from "../icons";
import {ParameterTree} from "../ai";
import {Transition} from "../Transition";

export type SectionProps = {
    tree: ParameterTree;
    update: (tree: ParameterTree) => void;
    cancel: () => void;
    commit: (tree?: ParameterTree) => void;
}

type SectionCardProps = {
    title: string;
    subtitle: string;
    illustration: React.ComponentType<React.SVGProps<any>>;
    cancel: () => void;
    commit: () => void;
}

export const SectionCardPage: React.FunctionComponent<SectionCardProps> = (props) => {

    // disable scroll for body on mount + enable on unmount
    useEffect(() => {
        document.querySelector('body')!.classList.add('overflow-hidden');
        return () =>
            document.querySelector('body')!.classList.remove('overflow-hidden');
    });

    const Illustration = props.illustration;



    return (
        <div className="w-full fixed inset-0">
            <Transition
                enter="ease-out duration-100" enterFrom="opacity-0" enterTo="opacity-100"
            >
                <div className="fixed inset-0">
                    <div className="absolute inset-0 bg-backdrop"></div>
                </div>
            </Transition>
            <div className="relative w-full sm:py-8 h-full">
                <Transition
                    enter="ease-out duration-100" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100"
                >
                    <div className="relative max-h-full overflow-hidden container bg-white shadow sm:rounded-sm flex flex-col transform transition-all">
                        <div className="absolute top-0 right-0 p-4">
                            <Link to="/"
                                  className="rounded-full text-gray-800 hover:bg-gray-200 hover:text-black p-2 flex items-center justify-center transition duration-150 ease-in-out">
                                <Close className="w-6 h-6"/>
                            </Link>
                        </div>
                        <div className="px-4 py-5 sm:px-6 flex items-center justify-between flex-shrink-0">
                            <div className="leading-8">
                                <h3 className="text-2xl font-medium text-gray-900 font-bold">
                                    {props.title}
                                </h3>
                                <p className="mt-1 text-xl text-gray-800 tracking-wider font-light">
                                    {props.subtitle}
                                </p>
                            </div>
                            <div className="hidden sm:block">
                                <Illustration className="object-scale-down h-24 w-full text-brand" />
                            </div>
                        </div>

                        <div className="flex flex-col overflow-y-auto flex-shrink">
                            {props.children}
                        </div>

                        <div className="px-4 flex-shrink-0">
                            <div className="flex flex-1 border-t border-gray-300 items-center justify-end px-4 py-4 space-x-4">
                                <button onClick={() => props.cancel()} className="text-gray-700 tracking-wide">
                                    Abbrechen
                                </button>
                                <button className="bg-green-bright text-white py-3 px-10 rounded-full font-md text-sm tracking-wide" onClick={() => props.commit()}>
                                    {props.title} speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export const InlineSection: React.FunctionComponent<{ title: string, subtitle: string }> = (props) => (
    <div>
        <h2 className="font-bold text-xl">{props.title}</h2>
        <div className="text-sm text-gray-900 leading-4">
            {props.subtitle}
        </div>
        {props.children}
    </div>
);

type SectionCardTableProps = {
    items: {
        title: ReactNode;
        description?: ReactNode;
        main: ReactNode;
    }[];
};
export const SectionCardTable: React.FunctionComponent<SectionCardTableProps> =({ items }) => {
    return (
        <dl>
            {items.map(({ title, description, main}, idx) => (
                <div key={idx} className="px-4 py-5 flex flex-col space-y-4">
                    <dt className="flex flex-col tracking-wide">
                        <span className="text-bold leading-5 font-bold text-gray-900 tracking-wider">{title}</span>
                        {!!description && (
                            <span className="text-gray-800 font-light">{description}</span>
                        )}
                    </dt>
                    <dd className="mt-1 sm:mt-0 sm:col-span-2">
                        {main}
                    </dd>
                </div>
            ))}
        </dl>
    );
};

export const BigRadioButton: React.FunctionComponent<{ active: boolean, onClick: () => void }> = (props) => (
    <button
        className={`rounded-sm overflow-hidden shadow relative bg-white focus:shadow-outline px-6 py-4 font-bold text-xl text-gray-800 transition duration-150 ease-in-out border ${props.active ? 'border-brand shadow-md' : 'border-transparent'}`}
        onClick={props.onClick}
    >
        <span className="invisible">
            {props.children}
        </span>
        <div className={`absolute inset-0 flex items-center justify-center transition duration-150 ease-in-out ${props.active ? 'text-brand' : ''}`}>
            {props.children}
        </div>
    </button>
);

export const SelectableChip: React.FunctionComponent<{ active: boolean, onClick: () => void}> = (props) => (
    <button className={cx("rounded-full px-4 py-2 border hover:border-black hover:shadow focus:shadow-outline transition duration-150 ease-in-out", {
        "bg-brand text-white border-transparent": props.active,
        "border-gray-700 ": !props.active,
    })} onClick={props.onClick}>
        {props.children}
    </button>
);