import React from "react";
import {EmployeeCountRange} from "../ai";
import {BigRadioButton, InlineSection, SectionProps} from "./common";
import {employeeCountRageMapping} from "../labelMapping";


const countsInOrder: EmployeeCountRange[] = ['1-10', '11-50', '51-250', '250+'];

export const EmployeeCountInlineSection: React.FunctionComponent<SectionProps> = ({tree, commit}) => {
    return (
        <InlineSection title="Größe ihres Unternehmens" subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.">
            <div className="mt-4 flex flex-wrap justify-start -mx-4">
                {countsInOrder.map((employeeCount, idx) => (
                    <div key={employeeCount} className="mb-2 mx-4 w-40 flex flex-col items-stretch">
                        <BigRadioButton active={employeeCount === tree.general.employeeCount} onClick={() => commit({
                            ...tree,
                            general: {
                                ...tree.general,
                                employeeCount,
                            },
                        })}>
                            {employeeCountRageMapping[employeeCount]}
                        </BigRadioButton>
                    </div>
                ))}
            </div>
        </InlineSection>
    );

};