import React from "react";
import {objectivesById} from "../db/objective";
import {objectiveTypeColor} from "../ai";
import {StarRating} from "../components/StarRating";

type Props = {
    id: string;
    rating: number;
}

export const ObjectiveCardHeader: React.FunctionComponent<Props> = (props) => {

    const { name, imageUrl, description1, description2, aiObjectiveId } = objectivesById[props.id];
    const color = objectiveTypeColor[aiObjectiveId];

    return (
        <div className="flex flex-col md:flex-row print:flex-col-reverse space-y-4 md:space-y-0 md:space-x-8 print:space-y-0 print:space-x-0 print:pagebreak-after print:justify-center">
            <div className="md:w-3/5 print:w-auto flex flex-col print:mt-8">
                <div className="flex flex-row items-center space-x-4">
                    <h3 className="text-3xl font-bold">{name}</h3>
                    <StarRating value={props.rating} />
                </div>
                <div>
                    <p>
                        {description1}
                    </p>
                    <div className="h-4"/>
                    <p>
                        {description2}
                    </p>
                </div>
            </div>
            <div className="md:w-2/5 print:w-auto flex items-center">
                <div
                    className="w-full rounded overflow-hidden h-64"
                    style={{
                        backgroundColor: color,
                    }}
                >
                    <img
                        className="object-contain h-full w-full"
                        src={imageUrl}
                        alt="Illustration"
                    />
                </div>
            </div>
        </div>
    );
};