import React from "react";
import {ReactComponent as GeneralSvg} from "./illustrations/undraw_setup_wizard_r6mr.svg";
import {navigateTo} from "./util";

export const Hero = () => (
    <div className="flex flex-col sm:flex-row py-8 px-2 sm:px-12 space-y-6 sm:space-y-0 sm:space-x-6">
        <div className="sm:w-2/3 flex flex-col justify-center items-center sm:items-start">
            <h1 className="font-bold text-3xl leading-8 tracking-wide">
                Erstellen Sie schnell und einfach ihr maßgeschneidertes Marketingkonzept.
            </h1>
            <span className="leading-4 text-sm mt-2">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    </span>
            <div className="flex flex-row space-x-4 mt-6 items-center">
                <button
                    className="bg-green-bright hover:bg-opacity-75 text-white py-2 px-10 rounded-full font-md text-sm tracking-wide"
                    onClick={() => navigateTo('#start')}
                >
                    Jetzt starten
                </button>
                {/*TODO: Link für Terminvereinbaren einfügen*/}
                <a href='#TODO' className="tracking-wide text-gray-800 text-sm">Termin vereinbaren</a>
            </div>
        </div>
        <div className="sm:w-1/3">
            <GeneralSvg className="w-full text-brand"/>
        </div>
    </div>
);