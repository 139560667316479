import {BudgetRange, EmployeeCountRange, Industry, ObjectiveType} from "./ai";

export const objectiveTypeName: { [K in ObjectiveType]: string } = {
    reach: 'Bekanntheit steigern',
    image: 'Image verbessern',
    loyalty: 'Kundenbindung steigern',
    revenue: 'Umsätze steigern',
    employee: 'Mitarbeiter rekrutieren',
    wincustomer: 'Neukunden gewinnen',
};

export const industryMapping: { [K in Industry]: string } = {
    handwerk: 'Handwerk',
    pflege: 'Pflege',
    logistik: 'Logistik',
    kfz: 'KFZ',
    sonstige: 'Sonstige',
};

export const budgetRangeMapping: { [K in BudgetRange ]: string} = {
    '1-5000': '<5.000 €',
    '5000-10000': '5.000 - 10.000 €',
    '11000-25000': '11.000 - 25.000 €',
    '26000-50000': '26.000 - 50.000 €',
    '50000+': '>50.000 €',
};

export const employeeCountRageMapping: { [K in EmployeeCountRange ]: string} = {
    '1-10': '1-10',
    '11-50': '11-50',
    '51-250': '51-250',
    '250+': '>250',
};

export const introTextMapping: { [K in Industry]: string } = {
    handwerk: "Auf Basis 180 Jahren Erfahrung in der Handwerksbranche und den Ergebnissen unserer Marktforschung und Kundenumfragen haben wir ein Kommunikationskonzept für die Handwerksbranche entwickelt, dass Ihnen dabei helfen soll Ihre Unternehmensziele zu erreichen.",
    pflege: "Auf Basis 180 Jahren Erfahrung in der Pflege und den Ergebnissen unserer Marktforschung und Kundenumfragen haben wir ein Kommunikationskonzept für die Pflege entwickelt, dass Ihnen dabei helfen soll Ihre Unternehmensziele zu erreichen.",
    logistik: "Auf Basis 180 Jahren Erfahrung in der Logistikbranche und den Ergebnissen unserer Marktforschung und Kundenumfragen haben wir ein Kommunikationskonzept für die Logistikbranche entwickelt, dass Ihnen dabei helfen soll Ihre Unternehmensziele zu erreichen.",
    kfz: "Auf Basis 180 Jahren Erfahrung in der KFZ-Branche und den Ergebnissen unserer Marktforschung und Kundenumfragen haben wir ein Kommunikationskonzept für die KFZ-Branche entwickelt, dass Ihnen dabei helfen soll Ihre Unternehmensziele zu erreichen.",
    sonstige: 'Lorem impsum',
};
