import React from "react";
import {SectionCard} from "./SectionCard";
import {ReactComponent as targetSvg} from "./illustrations/undraw_target_kriv.svg";
import {ReactComponent as channelsSvg} from "./illustrations/undraw_mobile_marketing_iqbr.svg";
import {ReactComponent as eventsSvg} from "./illustrations/undraw_schedule_pnbk.svg";
import {ReactComponent as targetGroupSvg} from "./illustrations/undraw_celebration_0jvk.svg";
import {ReactComponent as measuresSvg} from "./illustrations/undraw_mobile_testing_reah.svg";
import {isSectionFinished} from "./ai";
import {Hero} from "./Hero";
import {BudgetInlineSection, EmployeeCountInlineSection, IndustryInlineSection} from "./sections";
import {InlineSection, SectionProps} from "./sections/common";
import {SectionProgress} from "./SectionProgress";


const cards = [
    {idx: 0, to: "/objectives", section: 'objectives', title: "Ziele", buttonLabel: 'Ziele hinzufügen', illustration: targetSvg},
    {idx: 1, to: "/targetgroup", section: 'targetGroup', title: "Zielgruppen", buttonLabel: 'Zielgruppe hinzufügen', illustration: targetGroupSvg},
    {idx: 2, to: "/channels", section: 'channels', title: "Kanäle", buttonLabel: 'Kanäle definieren', illustration: channelsSvg},
    {idx: 3, to: "/events", section: 'events', title: "Besondere Anlässe", buttonLabel: 'Anlässe hinzufügen', illustration: eventsSvg},
    {idx: 4, to: "/measures", section: 'measures', title: 'Aktuelle Maßnahmen', buttonLabel: 'Maßnahmen hinzufügen', illustration: measuresSvg},
] as const;

export const CardOverview: React.FunctionComponent<SectionProps> = (props) => {
    return (
        <div className="px-4 py-8 flex flex-col container">

            <Hero/>

            <div className="space-y-4" id="start">
                <IndustryInlineSection {...props}/>
                <EmployeeCountInlineSection {...props}/>
                <BudgetInlineSection {...props}/>
                <InlineSection title="Ihre Kommunikationsprofil" subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."/>
            </div>

            <div className="mt-4 container grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {cards.concat().sort((a, b) => a.idx - b.idx).map(card => (
                    <SectionCard
                        key={card.title}
                        title={card.title}
                        to={card.to}
                        illustration={card.illustration}
                        checked={isSectionFinished(props.tree, card.section)}
                        buttonLabel={card.buttonLabel}
                    />
                ))}
                <SectionProgress tree={props.tree}/>
            </div>
        </div>
    );
};