import React from "react";
import {CustomerType, MediaBehaviour, Regionality} from "../ai";
import {BigRadioButton, SectionCardPage, SectionCardTable, SectionProps} from "./common";
import {ReactComponent as targetGroupSvg} from "../illustrations/undraw_celebration_0jvk.svg";

const customerTypeMapping: { [K in CustomerType]: string } = {
    'B2B': 'B2B',
    'B2C': 'B2C',
};

const mediaBehaviourMapping: { [K in MediaBehaviour]: string } = {
    print: 'Print- / Out-of-Home',
    online: 'Online / Website',
    socialmedia: 'Social Media',
    telephone: 'Telefon / Fax',
};

const mediaBehaviourInOrder: MediaBehaviour[] = ['online', 'socialmedia', 'print', 'telephone'];

const regionalityMapping: { [K in Regionality]: string } = {
    nationwide: 'Überregional',
    regional: 'Regional',
};

export const TargetGroupSection: React.FunctionComponent<SectionProps> = ({tree, update, cancel, commit}) => {
    return (
        <SectionCardPage title="Zielgruppe"
                         subtitle="Helfen Sie uns ihre Zielgruppe zu definieren."
                         illustration={targetGroupSvg}
                         cancel={cancel}
                         commit={commit}
        >
            <SectionCardTable
                items={[
                    {
                        title: 'Art der Zielgruppe',
                        description: 'Handelt es sich bei ihrer Zielgruppe um Geschäftskunden (B2B) oder Endverbraucher (B2C)?',
                        main: (
                            <div className="flex flex-row space-x-2">
                                {(Object.keys(customerTypeMapping) as CustomerType[]).map((customerType) => (
                                    <BigRadioButton key={customerType}
                                                    active={tree.targetGroup.customerType === customerType}
                                                    onClick={() => update({
                                                        ...tree,
                                                        targetGroup: {
                                                            ...tree.targetGroup,
                                                            customerType,
                                                        },
                                                    })}>
                                        {customerTypeMapping[customerType]}
                                    </BigRadioButton>
                                ))}
                            </div>
                        ),
                    },
                    {
                        title: 'Medienverhalten',
                        description: 'Welches ist das Hauptmedium, über das man Ihre Zielgruppe am ehesten erreichen kann?',
                        main: mediaBehaviourInOrder.map((mediaBehaviour) => (
                            <label key={mediaBehaviour} className="flex items-center justify-start cursor-pointer">
                                <input
                                    type="radio"
                                    name="mediaBehaviour"
                                    checked={tree.targetGroup.mediaBehaviour === mediaBehaviour}
                                    onChange={(e) => update({
                                        ...tree,
                                        targetGroup: {
                                            ...tree.targetGroup,
                                            mediaBehaviour,
                                        },
                                    })}
                                    className="form-radio mx-2 text-brand"
                                />
                                <span>
                                    {mediaBehaviourMapping[mediaBehaviour]}
                                </span>
                            </label>
                        )),
                    },
                    {
                        title: 'Demographische Eigenschaften',
                        description: (
                            <>Beschreiben Sie die <strong className="font-bold">Altersstruktur</strong> ihrer Zielgruppe.</>
                        ),
                        main: (
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    className="form-input w-20 py-4 text-center"
                                    value={tree.targetGroup.demographic.ageStart}
                                    onChange={(e) => update({
                                        ...tree,
                                        targetGroup: {
                                            ...tree.targetGroup,
                                            demographic: {
                                                ...tree.targetGroup.demographic,
                                                ageStart: parseInt(e.target.value, 10),
                                            }
                                        }
                                    })}
                                />
                                <span className="w-8 text-center text-md font-bold">-</span>
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    className="form-input w-20 py-4 text-center"
                                    value={tree.targetGroup.demographic.ageEnd}
                                    onChange={(e) => update({
                                        ...tree,
                                        targetGroup: {
                                            ...tree.targetGroup,
                                            demographic: {
                                                ...tree.targetGroup.demographic,
                                                ageEnd: parseInt(e.target.value, 10),
                                            }
                                        }
                                    })}
                                />
                            </div>
                        ),
                    },
                    {
                        title: null,
                        description: (
                            <>Beschreiben Sie die <strong className="font-bold">regionale Verteilung</strong> ihrer Zielgruppe.</>
                        ),
                        main: (
                            <div className="flex flex-row space-x-2">
                                {(Object.keys(regionalityMapping) as Regionality[]).map((regionality) => (
                                    <BigRadioButton key={regionality}
                                                    active={tree.targetGroup.demographic.regionality === regionality}
                                                    onClick={() => update({
                                                        ...tree,
                                                        targetGroup: {
                                                            ...tree.targetGroup,
                                                            demographic: {
                                                                ...tree.targetGroup.demographic,
                                                                regionality,
                                                            }
                                                        },
                                                    })}>
                                        {regionalityMapping[regionality]}
                                    </BigRadioButton>
                                ))}
                            </div>
                        ),
                    },
                ]}
            />
        </SectionCardPage>
    );
};