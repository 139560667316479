import React from "react";
import {BigRadioButton, SectionCardPage, SectionCardTable, SectionProps, SelectableChip} from "./common";
import {ReactComponent as eventsSvg} from "../illustrations/undraw_schedule_pnbk.svg";
import {SeasonPeriod} from "../ai";

const periodMapping: { [K in SeasonPeriod ]: string } = {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mär',
    apr: 'Apr',
    mai: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Dez',
};

const periodsInOrder: SeasonPeriod[] = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const holidays = [
    'Neujahr',
    'Ostern',
    'Tag der Arbeit',
    'Christi Himmelfahrt',
    'Pfingsten',
    'Tag der deutschen Einheit',
    'Weihnachten',
];

const eventTypes = [
    'Messe',
    'Regionale Veranstaltung',
    'Fussball EM 2020',
    'Olympia 2020',
    'Betriebsfeiern',
];

export const EventsSection: React.FunctionComponent<SectionProps> = ({ tree, update: _update, commit, cancel }) => {
    const update = (events: Partial<typeof tree.events>) => _update({
        ...tree,
        events: {
            ...tree.events,
            ...events,
        },
    });
  return (
      <SectionCardPage title="Besondere Anlässe" subtitle="Gibt es Events oder besondere Anlässe an denen Sie ihre Marketingaktivitäten intensivieren möchten?" illustration={eventsSvg} cancel={cancel} commit={commit}>
          <SectionCardTable
              items={[
                  {
                      title: 'Saisonales Geschäft',
                      description: 'Sollten Sie während des Geschäftsjahres besonders umsatzrelevante Monate haben, wählen sie diese aus.',
                      main: (
                          <div className="mt-4 grid row-gap-6 grid-cols-4 md:grid-cols-6 lg:grid-cols-10 xl:grid-cols-12">
                              {periodsInOrder.map(period => (
                                  <div key={period} className="w-20 flex flex-col items-stretch">
                                      <BigRadioButton active={tree.events.periods.includes(period)} onClick={() => update({
                                          periods: toggleElement(tree.events.periods, period),
                                      })}>
                                          {periodMapping[period]}
                                      </BigRadioButton>
                                  </div>
                              ))}
                          </div>
                      )
                  },
                  {
                      title: 'Feiertage und Veranstaltungen',
                      description: 'Richten Sie ihr Marketing auf bestimmte Feiertage oder Veranstaltungen wie eine Messe aus?',
                      main: null,
                  },
                  {
                      title: 'Feiertage auswählen',
                      description: null,
                      main: (
                          <div className="flex flex-wrap -m-2">
                              {holidays.map(holiday => (
                                <div key={holiday} className="m-2">
                                    <SelectableChip active={tree.events.holidays.includes(holiday)} onClick={() => update({
                                        holidays: toggleElement(tree.events.holidays, holiday),
                                    })}>
                                        {holiday}
                                    </SelectableChip>
                                </div>
                              ))}
                          </div>
                      ),
                  },
                  {
                      title: 'Veranstaltungstypen auswählen',
                      description: null,
                      main: (
                          <div className="flex flex-wrap -m-2">
                              {eventTypes.map(type => (
                                  <div key={type} className="m-2">
                                      <SelectableChip active={tree.events.types.includes(type)} onClick={() => update({
                                          types: toggleElement(tree.events.types, type),
                                      })}>
                                          {type}
                                      </SelectableChip>
                                  </div>
                              ))}
                          </div>
                      ),
                  }
              ]}
          />
      </SectionCardPage>
  );
};

function toggleElement<T>(arr: T[], element: T): T[] {
    return arr.includes(element) ? arr.filter(e => e !== element) : [...arr, element];
}