import {ParameterTree} from "./ai";
import {allObjectives} from "./db/objective";

export const getObjectivesSortedByAi = (tree: ParameterTree) => [...allObjectives].sort((a, b) => {
    const aRaw = tree.objectives[a.aiObjectiveId];
    const bRaw = tree.objectives[b.aiObjectiveId];
    const A = aRaw === null ? -1 : aRaw;
    const B = bRaw === null ? -1 : bRaw;
    return B - A;
});

export const getSuggestionsForObjectiveByAi = (objectiveId: string, tree: ParameterTree): string[] => {
    switch (objectiveId) {
        case '001':
            return ['001'];
        case '002':
            return ['002', '003'];
        case '003':
            return ['004', '005'];
        case '004':
            return ['006'];
        case '005':
            return ['007'];
    }
    return [];
};

export const getProductsForSuggestionByAi = (suggestionId: string, tree: ParameterTree): string[] => {
    switch (suggestionId) {
        case "001":
            return ["001", "002", "003"];
        case "002":
            return ["101"];
        case "003":
            return ["102"];
        case "004":
            return ["101"];
        case "005":
            return ["007", "008", "009"];
        case "006":
            return ["001", "002", "003"];
        case "007":
            return ["201", "202"];
    }
    return [];
};