import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {CardOverview} from "./CardOverview";
import {Result} from "./result/Result";
import {ChannelsSection, EventsSection, MeasuresSection, ObjectivesSection, TargetGroupSection} from "./sections";
import {getInitialParamterTree, ParameterTree} from "./ai";
import {StageTreeProvider} from "./StageTreeProvider";
import {TransitionGroup} from "react-transition-group";


const routingTable = {
    '/objectives': ObjectivesSection,
    '/channels': ChannelsSection,
    '/events': EventsSection,
    '/targetgroup': TargetGroupSection,
    '/measures': MeasuresSection,
};

function App() {

    const [tree, setTree] = useState<ParameterTree>(getInitialParamterTree());

    const stagingProps = { tree, setTree };

    return (
        <Router>
            <Switch>
                <Route path="/result">
                    <Result tree={tree}/>
                </Route>
                <Route path="/">
                    <StageTreeProvider {...stagingProps}>
                        {(props) => <CardOverview {...props} />}
                    </StageTreeProvider>
                </Route>
                <Route path="/*">
                    <div>
                        <h1>Seite nicht gefunden</h1>
                        <Link to="/">Zur Startseite</Link>
                    </div>
                </Route>
            </Switch>
            <TransitionGroup>
                <Switch>
                    {Object.entries(routingTable).map(([path, Component]) => (
                        <Route key={path} path={path} exact>
                            <StageTreeProvider {...stagingProps}>
                                {(props) => <Component {...props} />}
                            </StageTreeProvider>
                        </Route>
                    ))}
                </Switch>
            </TransitionGroup>
        </Router>
    );
}

export default App;
