import {MutableRefObject, useEffect, useRef, useState} from "react";


export function useHover<T extends Element >(): {ref: MutableRefObject<T | null>, isHover: boolean } {
    const [isHover, setHover] = useState(false);

    const ref = useRef<T | null>(null);
    useEffect(() => {
        const node = ref.current;
        if (!node) {
            return;
        }

        const handleMouseOver = () => setHover(true);
        const handleMouseOut = () => setHover(false);

        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
            node.removeEventListener("mouseover", handleMouseOver);
            node.removeEventListener("mouseout", handleMouseOut);
        };

    }, [ref, setHover]);

    return {ref, isHover};
}