export type Suggestion = {
    id: string;
    name: string;
    imageUrl: string;
    description: string;
    benefits: string[];
}

const allSuggestions: Suggestion[] = [
    {
        id: '001',
        name: 'Persönliche Werbegeschenke für treue Kunden',
        imageUrl: 'https://www.berendsohn.de/assets/img/editionen/bloek-4.png',
        description: 'Persönlich Präsente haben einen besonderen Wert für den Beschenkten, seien es Ihre Kunden, Geschäftspartner oder Mitarbeiter. Ein schönes Geschenk schafft ein postitives Erlebnis, das in Erinnerung bleibt. Ihre Werbebotschaft ist so täglich im Blickfeld des Betrachters: am Arbeitsplatz oder im privaten Umfeld.',
        benefits: [
            'Alle Geschenke zum Anfassen und Ausprobieren',
            'Individuelle Werbeanbringung und hochwertige Veredelung',
        ],
    },
    {
        id: '002',
        name: 'Ein moderner Online Auftritt',
        imageUrl: 'https://www.berendsohn.de/assets/img/teasers/teaser-websites.jpg',
        description: 'Die Präsenz und Sichtbarkeit Ihres Unternehmens im Internet ist heutzutage eine Voraussetzung für Erfolg. Wir realisieren schnell und unkompliziert Ihre neue, zeitgemäße Website.',
        benefits: [
            'Modernes, responsives Design',
            'Erstellung inklusive Text und Bildern',
            'Unkomplizierte und kostengünstige Umsetzung',
        ],
    },
    {
        id: '003',
        name: 'Ihre Unternehmensdaten immer up-to-date',
        imageUrl: 'https://www.berendsohn.de/assets/img/teasers/teaser-local-listing.jpg',
        description: 'Sie möchten mehr Kontrolle über Ihre Daten im Netz und besser gefunden werden? Mit unserem Service Local Listing erreichen Sie eine einheitliche und korrekte Darstellung Ihrer Unternehmensdaten im Netz. Starten Sie jetzt Ihr Digital-Update.',
        benefits: [
            'Kontrolle über alle Ihre Unternehmensdaten',
            '100+ digitale Dienste auf der ganzen Welt',
            'Ideale Ergänzung zu ihrer Website',
        ],
    },
    {
        id: '004',
        name: 'Hochwertige und funktionale Mitarbeiterbekleidung',
        imageUrl: 'https://www.berendsohn.de/assets/img/teasers/teaser-fashion.jpg',
        description: 'Nutzen Sie die Chance, Ihre Unternehmenswerte zu präsentieren und sich so zu zeigen, wie Sie sind. Die Investion lohnt sicht, denn ein einheitlicher Unternehmensauftritt stärkt nachweislich das Vertrauen in die Kompetenz des Unternehmens sowie die Bekanntheit in Ihren Kundenkreisen. Ihre Kunden und Mitarbeiter werden den neuen Look lieben.',
        benefits: [
            'Stärkt das Vertrauen und den Wiedererkennungswert',
            'Große Textilvielfalt und hochwertige Veredelung',
        ],
    },
    {
        id: '005',
        name: 'Geschäftsausstattung für einen professionellen Look',
        imageUrl: 'https://www.berendsohn.de/pix/cat/teaser-print.jpg',
        description: 'Nutzen Sie die Chance, Ihre Unternehmenswerte zu präsentieren und sich so zu zeigen, wie Sie sind. Die Investion lohnt sicht, denn ein einheitlicher Unternehmensauftritt stärkt nachweislich das Vertrauen in die Kompetenz des Unternehmens sowie die Bekanntheit in Ihren Kundenkreisen. Ihre Kunden und Mitarbeiter werden den neuen Look lieben.',
        benefits: [
            'Alles aus einer Hand',
            'Große Textilvielfalt und hochwertige Veredelung',
        ],
    },
    {
        id: '006',
        name: 'Streuartikel für Event- und Guerilla-Marketing nutzen',
        imageUrl: 'https://www.berendsohn.de/pix/cat/teaser-accessoires.jpg',
        description: 'Sie möchten bei Ihrem Messebesuch in erinnerung bleiben oder Ihre Kunden dazu bringen, für Ihr Unternehmen zu werben? Werbeartikel von Berendsohn unterstützen Sie dabei! Unsere originellen Produkte fallen auf und werden selbstvertändliche Begleiter Ihrer Kunden. Ihre Werbebotschaft ist so täglich im Blickfeld Ihres Kunden: am Arbeitsplatz oder im privaten Umfeld.',
        benefits: [
            'Alle Artikel zum Anfassen und Ausprobieren',
            'Ausgezeichnete Qualität und hochwertige Veredelung',
        ],
    },
    {
        id: '007',
        name: 'Das passende Design für Ihr Unternehmen',
        imageUrl: 'https://www.berendsohn.de/assets/img/teasers/teaser-kreativservices.jpg',
        description: 'Was sagen Form, Schrift und Farbe Ihres Logos über Ihr Unternehmen aus? Ist es noch zeitgemäß? Passt es noch zu Ihnen? Wir beraten Sie gerne und verhelfen Ihnen schnell und unkompliziert zu Ihrem individuellen, zeitgemäßen Logodesign. Wie sieht es mit Texten und Bildern (Videos) auf Ihrerer Website aus? Auch hier finden wir die richtigen Kreativlösungen für Ihre professionelle Unternehmensdarstellung.',
        benefits: [
            'Berücksichtigung aller Dateiformate und Einsatzbereiche',
            'Unkomplizierte und kostengünstige Umsetzung',
        ],
    },
];

export const suggestionsById: { [id: string]: Suggestion } = allSuggestions.reduce((acc, sugg) => ({
    ...acc,
    [sugg.id]: sugg,
}), {});