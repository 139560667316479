import * as React from 'react';
import cx from "classnames";
import {Check, Edit} from "./icons";
import {useHover} from "./hooks/useHover";
import {Link} from "react-router-dom";

type Props = {
    title: string;
    to: string;
    buttonLabel: string;
    illustration: React.ComponentType<React.SVGProps<any>>;
    checked: boolean;
}

export const SectionCard: React.FunctionComponent<Props> = ({to, title, illustration: Illustration, checked, buttonLabel}) => {

    const {ref, isHover} = useHover<HTMLDivElement>();

    return (
        <div ref={ref}>
            <Link
                to={to}
                className="bg-white rounded shadow hover:shadow-md border border-gray-200 h-64 flex flex-col justify-center items-center relative"
            >
                <div className={`mb-2 transition-transform duration-300 transform ${isHover ? 'scale-110' : 'scale-100'}`}>
                    <Illustration className="object-scale-down h-24 w-full text-brand"/>
                </div>
                <div className="text-2xl font-bold tracking-wider">{title}</div>
                <div className="w-full px-4 py-6">
                    <hr className="w-full"/>
                </div>
                <button className={cx("border rounded-full py-2 px-4 inline-flex items-center transistion duration-150 ease", {
                    'bg-brand text-white border-transparent': isHover,
                    'bg-green-bright text-white border-transparent': checked && !isHover,
                    'text-gray-900 border-gray-600': !checked && !isHover,
                })}>
                    {checked && !isHover ? <Check className="w-4 h-4 mr-2" /> : <Edit className="w-4 h-4 mr-2"/>}
                    {checked && !isHover ? `${title} gespeichert` : buttonLabel}
                </button>

            </Link>
        </div>
    );
};