import React from "react";
import {Industry} from "../ai";
import {BigRadioButton, InlineSection, SectionProps} from "./common";
import {industryMapping} from "../labelMapping";

const industryInOrder: Industry[] = ['handwerk', 'pflege', 'kfz', 'logistik', 'sonstige'];

export const IndustryInlineSection: React.FunctionComponent<SectionProps> = ({tree, commit}) => {
    return (
        <InlineSection title="Ihre Branche" subtitle="Jede Branche ist anders und braucht individuelle Maßnahmen und Empfehlungen. Mit Ihrer Mithilfe können wir Ihnen eine maßgeschneiderte Lösung anbieten. Wählen Sie dafür zunächst eine Branche aus oder wählen Sie Sonstige Branchen.">
            <div className="mt-4 flex flex-wrap justify-start -mx-4">
                {industryInOrder.map((industry, idx) => (
                    <div key={industry} className="mb-2 mx-4 w-40 flex flex-col items-stretch">
                        <BigRadioButton active={industry === tree.general.industry} onClick={() => commit({
                            ...tree,
                            general: {
                                ...tree.general,
                                industry,
                            }
                        })}>
                            {industryMapping[industry]}
                        </BigRadioButton>
                    </div>
                ))}
            </div>
        </InlineSection>
    );

};