
const easeInOut = (t: number) => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1;

export function navigateTo(anchor: string) {
    const el = document.querySelector(anchor);

    if (!el) {
        console.error(`${anchor} not found`);
        return;
    }

    const duration = 400;

    const initial = window.scrollY;
    const top = el.getBoundingClientRect().top;

    var start: number;

    requestAnimationFrame(function step(ts) {
       if (!start) {
           start = ts;
       }

       const dT = ts - start;

       const percent = easeInOut(Math.min(dT / duration, 1));

       window.scrollTo(0, initial + (top * percent));

       if (dT < duration) {
           requestAnimationFrame(step)
       }
    });
}