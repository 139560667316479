import {Star} from "../icons";
import cx from "classnames";
import React from "react";

type Props = {
    value: number;
    onChange?: (value: number) => void;
};

export const StarRating: React.FunctionComponent<Props> = ({ value, onChange }) => (
    <div className={cx("flex flex-row space-x-2", {
        "pointer-events-none": !onChange,
    })}>
        {[...new Array(5)].map((_, i) => (
            <button key={i} disabled={!onChange} onClick={() => onChange && onChange(i + 1)}>
                <Star
                    className={cx("w-4 h-4", { "text-brand": value >= i + 1 && !!onChange })}
                    filled={value >= i + 1}
                />
            </button>
        ))}
    </div>
);