import React from "react";
import {Check} from "../icons";
import {useHover} from "../hooks/useHover";
import {productsById} from "../db/product";
import {suggestionsById} from "../db/suggestion";


type SuggestedMeasureProps = {
    id: string;
    suggestedProducts: string[];
}

export const SuggestedMeasure: React.FunctionComponent<SuggestedMeasureProps> = (props) => {

    const { name, imageUrl, description, benefits } = suggestionsById[props.id];

    return (
        <>
            <div className="flex flex-col md:flex-row items-center">
                <div className="rounded shadow overflow-hidden h-48 md:w-1/3">
                    <img
                        className="object-cover h-full w-full"
                        src={imageUrl}
                        alt={name}/>
                </div>
                <div className="ml-0 md:ml-8 mt-8 md:mt-0 flex-grow md:w-2/3 mt-4">
                    <h5 className="text-xl font-medium">
                        {name}
                    </h5>
                    <p>
                        {description}
                    </p>
                    <div className="mt-2">
                        {benefits.map((text) => (
                            <div key={text} className="flex flex-row items-center py-2">
                                <Check className="text-brand h-6 w-6 mr-2"/>
                                <span className="text-lg font-medium leading-none tracking-wide">{text}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="print:pagebreak-avoid">
                <h4 className="text-2xl font-medium my-6">Empfehlungen aus unserem Sortiment</h4>

                <div className="overflow-y-auto print:overflow-visible flex flex-row print:flex-wrap">
                    {props.suggestedProducts.map((id) => (
                        <ProductCard key={id} id={id}/>
                    ))}
                </div>
            </div>
        </>
    );
};


type ProductCardProps = {
    id: string;
}

const ProductCard: React.FunctionComponent<ProductCardProps> = ({ id }) => {
    const {isHover, ref} = useHover<HTMLDivElement>();

    const transition = (base: string = '') =>
        ('transition duration-200 ' + base).trimEnd();

    const hoverTextColor = (base: string = '') =>
        transition(`${isHover ? 'text-white' : 'text-black'} ${base}`);

    const { name, imageUrl, vendor } = productsById[id];

    return  (
        <div ref={ref} className="mb-4 mr-4 shadow hover:shadow-lg">
            <div className="w-64 print:w-48 relative rounded-sm bg-white overflow-hidden">
                <img className="w-10 h-10 absolute m-2" src="https://www.berendsohn.de/assets/img/signet.svg" alt="Signet"/>
                <img src={imageUrl} alt=""/>
                <div className={transition(`p-4 ${isHover ? 'bg-brand' : 'bg-white'}`)}>
                    <h4 className={hoverTextColor('text-xl font-bold')}>
                        {name}
                    </h4>
                    <span className={hoverTextColor()}>
                        {vendor}
                    </span>
                </div>
            </div>
        </div>
    );
};

