import React from "react";
import {BudgetRange} from "../ai";
import {BigRadioButton, InlineSection, SectionProps} from "./common";
import {budgetRangeMapping} from "../labelMapping";


const budgetInOrder: BudgetRange[] = ['1-5000', '5000-10000', '11000-25000', '26000-50000', '50000+'];

export const BudgetInlineSection: React.FunctionComponent<SectionProps> = ({tree, commit}) => {
    return (
        <InlineSection title="Ihr Marketingbudget" subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.">
            <div className="mt-4 flex flex-wrap justify-start -mx-1">
                {budgetInOrder.map((budget, idx) => (
                    <div key={budget} className="mb-2 mx-1 flex flex-col items-stretch">
                        <BigRadioButton active={budget === tree.general.budget} onClick={() => commit({
                            ...tree,
                            general: {
                                ...tree.general,
                                budget,
                            },
                        })}>
                            {budgetRangeMapping[budget]}
                        </BigRadioButton>
                    </div>
                ))}
            </div>
        </InlineSection>
    );

};