import React from "react";
import {canReportBeGenerated, numSections, numSectionsFinished, ParameterTree, percentFinished} from "./ai";
import {Link} from "react-router-dom";

export const SectionProgress: React.FunctionComponent<{ tree: ParameterTree }> = ({ tree }) => {
    const progress = percentFinished(tree);

    const numFinished = numSectionsFinished(tree);
    const numTotalSections = numSections(tree);

    const ready = canReportBeGenerated(tree);

    return (
        <div className="flex flex-col items-center justify-center space-y-4">

            <div className="relative px-24">
                <div className="absolute inset-0 flex items-center justify-center">
                    <span className="font-bold text-2xl">{Math.floor(progress * 100)}%</span>
                </div>
                <svg viewBox="0 0 150 150" className="w-full">
                    <circle stroke="#cfcfcf" strokeWidth={10} r={60} cx={75} cy={75} fill="transparent"/>
                    <circle
                        stroke={ready ? '#21c86e' : '#ffb100'}
                        strokeWidth={10}
                        r={60}
                        cx={75}
                        cy={75}
                        fill="transparent"
                        style={{
                            strokeDasharray: `${60 * 2 * Math.PI} ${60 * 2 * Math.PI}`,
                            strokeDashoffset: `${(60 * 2 * Math.PI) - progress * (60 * 2 * Math.PI)}`,
                            transform: 'rotate(-90deg)',
                            transformOrigin: '50% 50%',
                            transition: 'stroke-dashoffset 0.35s',
                        }}
                    />
                </svg>
            </div>


            <span className="text-gray-700 text-sm tracking-wide">
                {numFinished}/{numTotalSections} Bereiche beantwortet
            </span>
            {ready && (
                <Link to="/result" className="bg-green-bright hover:bg-opacity-75 text-white py-2 px-10 rounded-full font-md text-sm tracking-wide">
                    Marketingplan erstellen
                </Link>
            )}
            {!ready && (
                <span>Bitte füllen Sie noch mehr Bereiche aus!</span>
            )}

        </div>
    )
};