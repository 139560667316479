import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {SectionProps} from "./sections/common";
import {ParameterTree} from "./ai";
import {useHistory} from "react-router-dom";

type Props = {
    tree: ParameterTree,
    setTree: (tree: ParameterTree) => void;

    children: (props: SectionProps) => (ReactElement | null);
}

export const StageTreeProvider: React.FunctionComponent<Props> = ({ tree, setTree, children}) => {
    const history = useHistory();
    const onCancel = useCallback(() => {
        history.push('/');
    }, [history]);
    const onCommit = useCallback(() => {
        history.push('/');
    }, [history]);

    const [pending, setPending] = useState<ParameterTree>(tree);
    useEffect(() => setPending(tree), [tree, setPending]);



    const commit = useCallback((tree?: ParameterTree) => {
        if (!!tree) {
            setPending(tree);
            setTree(tree);
        } else {
            setTree(pending);
        }
        onCommit();
    }, [onCommit, setTree, pending]);

    const props = {
        tree: pending,
        update: setPending,
        cancel: onCancel,
        commit,

    };

    return children(props);
};