import React, {useEffect, useMemo} from "react";
import {ObjectiveCardHeader} from "./ObjectiveCardHeader";
import {SuggestedMeasure} from "./SuggestedMeasure";
import {BackArrow, Printer} from "../icons";
import {Link, Redirect, useHistory} from "react-router-dom";
import {ParameterTree, Industry, ObjectiveType} from "../ai";
import {getObjectivesSortedByAi, getProductsForSuggestionByAi, getSuggestionsForObjectiveByAi} from "../aiRecommender";
import {StarRating} from "../components/StarRating";
import {
    budgetRangeMapping,
    employeeCountRageMapping,
    industryMapping,
    introTextMapping,
    objectiveTypeName
} from "../labelMapping";
import {objectivesById} from "../db/objective";
import {navigateTo} from "../util";


export const Result: React.FunctionComponent<{ tree: ParameterTree }> = ({tree}) => {
    const history = useHistory();
    useEffect(() => {
        // scroll to top on launch
        window.scrollTo(0, 0);
    }, [history]);

    const objectives = useMemo(() =>
        getObjectivesSortedByAi(tree)
            .filter(({ aiObjectiveId }) => tree.objectives[aiObjectiveId] !== null),
        [tree],
    );

    type _T = {[id: string]: { id: string, products: string[] }[]};
    const measuresByObjective = objectives.reduce((acc: _T, objective) => ({
        ...acc,
        [objective.id]: getSuggestionsForObjectiveByAi(objective.id, tree)
            .map(id => ({
                id,
                products: getProductsForSuggestionByAi(id, tree),
            })),
    }), {});

    if (tree.general.industry === null) {
        return (
            <Redirect to="/"/>
        );
    }

    return (
        <div className="px-4 py-8 flex flex-col container">
            <div className="py-4 flex items-center justify-between print:hidden">
                <Link to="/"
                      className="transition duration-150 ease-in-out bg-white hover:bg-gray-200 shadow-lg rounded-full flex items-center px-4 py-2">
                    <BackArrow className="mr-2"/>
                    Zurück&nbsp;<span className="hidden sm:block">zur Bearbeitung</span>
                </Link>

                <div className="flex items-center">
                    <button
                        onClick={() => window.print()}
                        className="rounded-full transition duration-150 ease-in-out bg-white hover:bg-gray-200 shadow-lg p-2"
                    >
                        <Printer className="w-6 h-6" />
                    </button>
                </div>
            </div>
            <ResultHeader tree={tree}/>
            <div className="container">

                <ObjectiveIntro industry={tree.general.industry}/>

                {objectives.map((obj, idx) => (
                    <React.Fragment key={obj.id}>
                        <ObjectiveCard id={obj.id} measures={measuresByObjective[obj.id]} rating={tree.objectives[obj.aiObjectiveId] ?? 0}/>
                        {idx !== objectives.length && (
                            <div className="h-8 print:pagebreak-after"/>
                        )}
                    </React.Fragment>
                ))}

                <div className="print:hidden flex flex-row items-center justify-center space-x-4">
                    <button onClick={() => navigateTo('body')} className="uppercase bg-green-bright hover:bg-opacity-75 text-white py-3 px-10 rounded-full font-bold text-sm tracking-wider">
                        Nach oben
                    </button>
                    {/*TODO: Link für Terminvereinbaren einfügen*/}
                    <a href='#TODO' className="tracking-wider text-gray-800 text-sm uppercase font-bold">Beratungstermin vereinbaren</a>
                </div>
            </div>
        </div>
    )
};

const ResultHeader: React.FunctionComponent<{ tree: ParameterTree }> = ({ tree }) => (
    <div className="flex flex-col md:flex-row md:space-x-6">
        <div className="grid grid-cols-2 gap-4 md:w-2/5">
            <div className="border border-gray-400 flex flex-col items-center justify-center">
                <span>Branche</span>
                <span className="font-bold text-xl">{industryMapping[tree.general.industry!]}</span>
            </div>
            <div className="grid grid-cols-1 gap-4">
                <div className="border border-gray-400 flex flex-col items-center justify-center p-4">
                    <span>Mitarbeiter</span>
                    <span className="font-bold text-xl">{employeeCountRageMapping[tree.general.employeeCount!]}</span>
                </div>
                <div className="border border-gray-400 flex flex-col items-center justify-center p-4">
                    <span>Marketingbudget</span>
                    <span className="font-bold text-xl">{budgetRangeMapping[tree.general.budget!]}</span>
                </div>
            </div>
        </div>

        <div className="md:w-3/5 flex flex-col space-y-8">
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-4">
                <h2 className="font-bold text-xl">Ihre Ziele</h2>
                <span className="lg:col-span-2 font-light">Priorität</span>

                {(Object.keys(tree.objectives) as ObjectiveType[])
                    .filter(o => tree.objectives[o] !== null)
                    .sort((a, b) => tree.objectives[b]! - tree.objectives[a]!)
                    .map(o => (
                    <React.Fragment key={o}>
                        <div className="flex items-center tracking-wide">{objectiveTypeName[o]}</div>
                        <div className="lg:col-span-2 flex flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0 lg:space-x-4">
                            <StarRating value={tree.objectives[o] ?? 0}/>
                            <button onClick={() => navigateTo(`#ziel-${o}`)} className="uppercase font-light tracking-wide text-gray-800 print:hidden">
                                Zu den Empfehlungen
                            </button>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="flex items-center space-x-4 print:hidden">
                <button onClick={() => navigateTo('#konzept')} className="uppercase bg-green-bright hover:bg-opacity-75 text-white py-3 px-10 rounded-full font-bold text-sm tracking-wider">
                    Zum Marketingkonzept
                </button>
                {/*TODO: Link für Terminvereinbaren einfügen*/}
                <a href='#TODO' className="tracking-wider text-gray-800 text-sm uppercase font-bold">Beratungstermin vereinbaren</a>
            </div>
        </div>
    </div>
);

const ObjectiveIntro: React.FunctionComponent<{ industry: Industry }> = ({ industry}) => (
    <div id="konzept" className="space-y-2 my-6 print:pagebreak-after">
        <h2 className="font-bold text-3xl tracking-wide space-x-2">
            Ihr persönliches Marketingkonzept
        </h2>
        <p>
            {introTextMapping[industry]}
        </p>
        <p>
            Im Folgenden finden Sie Vorschläge zu effektiven Marketingmaßnahmen,
            die Ihnen dabei helfen sollen ihre Ziele zu erreichen.
            Zu jeder Maßnahme finden Sie passende Produktempfehlungen aus unserem Sortiment.
            Falls Sie eine ausführliche persönliche Beratung auf Basis ihres Marketingkonzeptes wünschen können Sie
            jederzeit einen <a className="font-bold" href="#TODO">Termin mit Ihrem Berater vereinbaren</a>.
        </p>
    </div>
);

const ObjectiveCard: React.FunctionComponent<{ rating: number, id: string, measures: { id: string, products: string[] }[] }> = (props) => {
    return (
        <div id={`ziel-${objectivesById[props.id].aiObjectiveId}`} className="rounded border print:border-0 px-4 pt-8 pb-4 border-brand print:pagebreak-avoid space-y-8">
            <ObjectiveCardHeader id={props.id} rating={props.rating}/>
            <div className="print:pagebreak-avoid">
                <h4 className="text-2xl font-medium mb-4">Vorgeschlagene Maßnahme</h4>
                {props.measures.map((measure, idx) => (
                    <React.Fragment key={measure.id}>
                        <SuggestedMeasure
                            id={measure.id}
                            suggestedProducts={measure.products}
                        />
                        {idx !== props.measures.length && (
                            <div className="h-8"/>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
