

export type ObjectiveType = 'reach' | 'image' | 'loyalty' | 'revenue' | 'employee' | 'wincustomer';

// TODO: pull from DB
export const objectiveTypeColor: { [ot in ObjectiveType]: string } = {
    reach: '#313282',
    image: '#FF425C',
    loyalty: '#0096C3',
    revenue: '#f0f',
    employee: '#FFB100',
    wincustomer: '#006577',
};

export type Section = 'objectives' | 'channels' | 'events' | 'targetGroup' | 'general' | 'measures';

export type Industry = 'handwerk' | 'pflege' | 'logistik' | 'kfz' | 'sonstige';

export type ChannelType = 'website' | 'socialmedia' | 'newsletter' | 'gifts' | 'print' | 'other' | 'equipment';

export type MediaBehaviour = 'print' | 'online' | 'socialmedia' | 'telephone';
export type CustomerType = 'B2B' | 'B2C';

export type EmployeeCountRange =
    | '1-10'
    | '11-50'
    | '51-250'
    | '250+';

export type BudgetRange =
    | '1-5000'
    | '5000-10000'
    | '11000-25000'
    | '26000-50000'
    | '50000+';

export type Regionality =
    | 'regional'
    | 'nationwide';

export type SeasonPeriod =
    | 'jan'
    | 'feb'
    | 'mar'
    | 'apr'
    | 'mai'
    | 'jun'
    | 'jul'
    | 'aug'
    | 'sep'
    | 'oct'
    | 'nov'
    | 'dec';

export type LikertScale = 0 | 1 | 2 | 3 | 4 | 5;

export type ParameterTree  = Readonly<{
    objectives: { [K in ObjectiveType]: LikertScale | null };
    channels: { [K in ChannelType]: boolean };
    targetGroup: {
        demographic: {
            ageStart: number,
            ageEnd: number,
            regionality: Regionality | null,
        },
        mediaBehaviour: MediaBehaviour | null,
        customerType: CustomerType | null,
    },
    general: {
        budget: BudgetRange | null,
        industry: Industry | null,
        employeeCount: EmployeeCountRange | null,
    },
    events: {
        periods: SeasonPeriod[],
        holidays: string[],
        types: string[],
    },
    measures: {
        [K in ChannelType]: null | {
            intensity: LikertScale,
            happiness: LikertScale | null,
        };
    },
}>;

export const getInitialParamterTree = (): ParameterTree => ({
    objectives: {
        reach: null,
        image: null,
        loyalty: null,
        revenue: null,
        employee: null,
        wincustomer: null,
    },
    channels: {
        website: false,
        socialmedia: false,
        newsletter: false,
        gifts: false,
        print: false,
        other: false,
        equipment: false,
    },
    targetGroup: {
        demographic: { ageStart: 0, ageEnd: 100, regionality: null, },
        mediaBehaviour: null,
        customerType: null,
    },
    general: {
        budget: null,
        industry: null,
        employeeCount: null,
    },
    events: {
        periods: [],
        holidays: [],
        types: [],
    },
    measures: {
        website: null,
        socialmedia: null,
        newsletter: null,
        gifts: null,
        print: null,
        other: null,
        equipment: null,
    }
});

export const canReportBeGenerated = (tree: ParameterTree): boolean =>
    isSectionFinished(tree, "general") &&
    isSectionFinished(tree, "objectives");

export const isSectionFinished = (tree: ParameterTree, section: Section) => {
  switch (section) {
      case "objectives":
          return hasAny(Object.values(tree.objectives).map(v => v !== null));
      case "channels":
          return hasAny(Object.values(tree.channels));
      case "targetGroup":
          return hasAll(Object.values(tree.targetGroup).map(v => v !== null))
              && tree.targetGroup.demographic.regionality !== null;
      case "general":
          return hasAll(Object.values(tree.general).map(v => v !== null));
      case "events":
          return hasAny(Object.values(tree.events).map(arr => arr.length > 0));
      case "measures":
          return hasAny(Object.values(tree.measures).map(v => v!== null));
      default:
          return false;
  }
};

export const percentFinished = (tree: ParameterTree) =>
    (Object.keys(tree) as (keyof ParameterTree)[])
        .map(section => percentFinishedInSection(tree, section))
        .reduce((a, b) => a + b, 0) / numSections(tree);

const percentFinishedInSection = (tree: ParameterTree, section: Section): number => {
  switch (section) {
      case "objectives":
          return isSectionFinished(tree, section) ? 1 : 0;
      case "channels":
          return isSectionFinished(tree, section) ? 1 : 0;
      case "events":
          return isSectionFinished(tree, section) ? 1 : 0;
      case "targetGroup":
          return (
              (tree.targetGroup.customerType !== null ? 1 : 0)
              + (tree.targetGroup.demographic.regionality !== null ? 1 : 0)
              + (tree.targetGroup.mediaBehaviour !== null ? 1 : 0)
          ) / 3;
      case "general":
          return Object.values(tree.general)
              .map(v => v !== null ? 1 : 0)
              .reduce((a: number, b: number) => a + b, 0) / Object.values(tree.general).length;
      case "measures":
          return isSectionFinished(tree, section) ? 1 : 0;
  }
};

export const numSectionsFinished = (tree: ParameterTree) => {
    return (Object.keys(tree) as (keyof ParameterTree)[])
        .map(section => isSectionFinished(tree, section) ? 1 : 0)
        .reduce((a: number, b: number) => a + b, 0);
};

export const numSections = (tree: ParameterTree) => Object.keys(tree).length;

const hasAny = (v: boolean[]) =>
    v.reduce((hasAny, v) => hasAny || v, false);

const hasAll = (v: boolean[]) =>
    v.reduce((hasAll, v) => hasAll && v, true);