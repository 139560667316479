import * as React from "react";

type Props = {
    className?: string;
}

export const BackArrow = ({className}: Props) => (
    <svg
        className={`h-4 w-4 fill-current ${className}`.trimRight()}
        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M3.828427 9l6.071068-6.071068-1.414214-1.414213L0 10l.707107.707107 7.778174 7.778174 1.414214-1.414213L3.828427 11H20V9H3.828427z" fillRule="evenodd"/>
    </svg>
);