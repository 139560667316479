

export type Product = {
  id: string;
  name: string;
  imageUrl: string;
  vendor: string;
};

const allProducts: Product[] = [
    {
        id: '001',
        name: 'Stammtisch Gläserset',
        imageUrl: 'https://www.berendsohn.de/pix/58/klein/0124260-STAMMTISCH.jpg',
        vendor: 'Berendsohn',
    },
    {
        id: '002',
        name: 'Prisma Lunchbox',
        imageUrl: 'https://www.berendsohn.de/pix/58/klein/0130310-GUNMETAL-FRISCHEBOX.jpg',
        vendor: 'Berendsohn',
    },
    {
        id: '003',
        name: 'Campo Multibörse',
        imageUrl: 'https://www.berendsohn.de/pix/58/klein/0137100-MULTIBOERSE-CAMPO.jpg',
        vendor: 'Berendsohn',
    },
    {
        id: '004',
        name: 'Caps, Mützen und Schals',
        imageUrl: 'https://placehold.it/400x450',
        vendor: 'Berendsohn',
    },
    {
        id: '005',
        name: 'T-Shirts, Pullover und Jacken',
        imageUrl: 'https://placehold.it/400x450',
        vendor: 'Berendsohn',
    },
    {
        id: '006',
        name: 'Arbeitskleidung und vieles mehr',
        imageUrl: 'https://placehold.it/400x450',
        vendor: 'Berendsohn',
    },
    {
        id: '007',
        name: 'Kalender',
        imageUrl: 'https://www.berendsohn.de/pix/58/klein/58136630_1.jpg',
        vendor: 'Berendsohn',
    },
    {
        id: '008',
        name: 'Schreibbedarf',
        imageUrl: 'https://www.berendsohn.de/pix/58/klein/58136600_1.jpg',
        vendor: 'Berendsohn',
    },
    {
        id: '009',
        name: 'Visitenkarten',
        imageUrl: 'https://www.berendsohn.de/pix/58/klein/58136710_2.jpg',
        vendor: 'Berendsohn',
    },
    {
        id: '101',
        name: 'Website Entwicklung',
        imageUrl: 'https://placehold.it/400x450',
        vendor: 'Berendsohn',
    },
    {
        id: '102',
        name: 'Local Listing',
        imageUrl: 'https://placehold.it/400x450',
        vendor: 'Berendsohn',
    },
    {
        id: '201',
        name: 'Logo-Paket Basic',
        imageUrl: 'https://www.berendsohn.de/pix/58/klein/D010000-BASIC-LOGO-DESIGN.jpg',
        vendor: 'Berendsohn',
    },
    {
        id: '202',
        name: 'Logo-Paket Advanced',
        imageUrl: 'https://www.berendsohn.de/pix/58/klein/D010010-ADVANCED-LOGO-DESIGN.jpg',
        vendor: 'Berendsohn',
    },
];

export const productsById: { [id: string]: Product } = allProducts.reduce((acc, prod) => ({
    ...acc,
    [prod.id]: prod,
}), {});