import {ObjectiveType} from "../ai";
import csmKundenbindung from "../illustrations/csm_Kundenbindung_leicht_gemacht_c_artistraman_Fotolia_98455094_web_d9108c4210@2x.png"
import reachIllustration from "../illustrations/reach-illustration.png";
import neukundenBooster from "../illustrations/neukunden-booster-2@2x.png";
import handschlag from "../illustrations/handschlag.png";

export type Objective = {
    id: string;
    name: string;
    imageUrl: string;
    description1: string;
    description2: string;

    aiObjectiveId: ObjectiveType;
};

export const allObjectives: Objective[] = [
    {
        id: '001',
        name: 'Kundenbindung steigern',
        imageUrl: csmKundenbindung,
        description1: 'In Zeiten zunehmender Digitalisierung besteht fast vollständige Transparenz über Produktportfolio, Services und Preis-Leistungs-Verhältnis von Unternehmen. Das führt zu einer steigenden Wechselbereitschaft bei Kunden.',
        description2: 'Kundenbindung ist deshalb das Zauberwort, um in diesem Wettbewerb zu bestehen. Steigern Sie die Kundenzufriedenheit und machen Sie jeden Touchpoint mit dem Kunden zum Erlebnis, z.B. durch persönliche Betreuung, Events, persönliche Anschreiben, Geschenke und orginelle Give Aways. Prämienprogramme sind ein weitereres gutes Tool, um Kunden nachhaltig an sich zu binden.',

        aiObjectiveId: 'loyalty',
    },
    {
        id: '002',
        name: 'Bekanntheit steigern',
        imageUrl: reachIllustration,
        description1: 'In der Masse verschiedenster Konkurrenzanbieter haben es Unternehmen heute nicht leicht. Kunden wollen aufmerksam gemacht, angesprochen, gewonnen und gehalten werden. Nutzen Sie Ihr Unternehmen als Bühne und zeigen Sie Kunden und Interessenten auf den ersten Blick wer Sie sind.',
        description2: 'Schöpfen Sie dabei alle digitalen Möglichkeiten aus: von Webseite, Social Media, SEO, SEA, Anzeigen, Local Listing und Newslettern. Empfehlungsmarketing ist ein weiteres wichtiges Instrument, um Ihre Bekanntheit zu steigern. Zufriedene Kunden sind die besten Multiplikatoren für Ihre Produkte und Leistungen. Machen Sie Ihr Unternehmen zu einer Marke mit einem hohen Wiedererkennungswert.',

        aiObjectiveId: 'reach',
    },
    {
        id: '003',
        name: 'Mitarbeiter finden und binden',
        imageUrl: neukundenBooster,
        description1: 'Investitionen in die Mitarbeiterzufriedenheit zahlen sich aus, denn gute und qualifizierte Mitarbeiter sind die Basis für Ihren unternehmerischen Erfolg. Die Loyalität und Zufriedenheit Ihrer Mitarbeiter sind gerade in Zeiten des Fachkräftemangels zentrale Erfolgsfaktoren und reduzieren nachweislich die Fluktuation und Fehlzeiten.',
        description2: 'Eine wertschätzende Unternehmenskultur ist dabei das „A“ und „O“ und dabei oft relevanter als die alleinige Sicht auf Gehälter. Machen Sie zukünftige Mitarbeiter*innen auf sich aufmerksam und erhöhen Sie Ihre Sichtbarkeit, sowohl in der digitalen als auch in der analogen Welt.',

        aiObjectiveId: 'employee',
    },
    {
        id: '004',
        name: 'Neukunden gewinnen',
        imageUrl: handschlag,
        description1: 'Ohne Kunden kein Geschäft - Kundenakquise ist die Basis für jede Form unternehmerischen Handelns. Teilweise stehen aber dieser Notwendigkeit hohe Kosten gegenüber, z.B. hohe Marketingausgaben. Gleichzeitig ist nicht gesichert diese Neukunden in Bestandskunden zu verwandeln.',
        description2: 'Es gilt also, clevere Konzepte der Neukundenakquise zu entwickeln immer vor dem Hintergrund einer rationalen Kosten/Nutzen-Analyse. Der wichtigste Tipp zur Neukundengewinnung ist Transparenz über Ihre Produkte und Leistungen herzustellen, sowohl digital als analog. Interessenten und Neukunden müssen auf den ersten Blick verstehen, wo Ihr USP liegt.',

        aiObjectiveId: 'wincustomer',
    },
    {
        id: '005',
        name: 'Image verbessern',
        imageUrl: '',
        description1: 'In Zeiten zunehmender Wettbewerbs ist eine einheitliche Außendarstellung und kosequente Markenführung ein wesentlicher Erfolgsgarant, um dauerhaft am Markt wahrgenommen zu werden und postitiv aufzufallen. Das Firmenlogo und der "Look" Ihres Unternehmensauftritts sind wichtige Eckpfeiler für die Corporate Identity Ihres Unternehmens.',
        description2: 'Nutzen Sie darüber hinaus Bewertungsportale für Ihre Ziele und animieren Sie zufriedene Kunden aktiv eine positive Bewertung für Sie abzugeben. Gehen Sie den Schritt mehr, um für Kunden nachhaltig positive Erlebnisse zu schaffen, die diese weiter tragen.',

        aiObjectiveId: 'image',
    },
];

export const objectivesById: { [id: string]: Objective } = allObjectives.reduce((acc, objc) => ({
    ...acc,
    [objc.id]: objc,
}), {});
