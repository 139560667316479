import React from "react";
import {ObjectiveType} from "../ai";
import {SectionCardPage, SectionProps} from "./common";
import {ReactComponent as targetSvg} from "../illustrations/undraw_target_kriv.svg";
import {StarRating} from "../components/StarRating";
import {objectiveTypeName} from "../labelMapping";


const objectivesInOrder: ObjectiveType[] = [
    'loyalty', 'image', 'reach', 'employee', 'revenue',
];

export const ObjectivesSection: React.FunctionComponent<SectionProps> = ({tree, update, cancel, commit}) => {
    return (
        <SectionCardPage title="Marketing Ziele" subtitle="Was möchten Sie mit Ihren Maßnahmen erreichen?" illustration={targetSvg} cancel={cancel} commit={commit}>
            <div className="px-4 pb-8">
                <div className="grid grid-cols-2 col-gap-6 font-bold tracking-wide">
                    <div>Ziel</div>
                    <div>Wichtigkeit</div>
                </div>
                {objectivesInOrder.map(objective => (
                    <div key={objective} className="grid grid-cols-2 col-gap-6 py-3">
                        <div className="flex items-center">
                            <label key={objective} className="space-x-2 tracking-wide text-gray-800 flex items-center">
                                <input
                                    checked={tree.objectives[objective] !== null}
                                    onChange={(e) => update({
                                        ...tree,
                                        objectives: {
                                            ...tree.objectives,
                                            [objective]: e.target.checked ? 0 : null,
                                        },
                                    })}
                                    type="checkbox"
                                    className="form-checkbox text-brand transition duration-150 ease-in-out border-gray-600 border-2"
                                />
                                <span>{objectiveTypeName[objective]}</span>
                            </label>
                        </div>
                        <StarRating value={tree.objectives[objective] ?? 0} onChange={(value) => update({
                            ...tree,
                            objectives: {
                                ...tree.objectives,
                                [objective]: tree.objectives[objective] === value ? null : value,
                            },
                        })} />
                    </div>
                ))}
            </div>
        </SectionCardPage>
    );

};