import React from "react";
import { CSSTransition } from 'react-transition-group'

type Props = {
    enter?: string;
    enterFrom?: string;
    enterTo?: string;
}

export const Transition: React.FunctionComponent<Props> = ({ enter, enterFrom, enterTo, children }) => {
    const enterClasses = (enter ?? '').split(' ');
    const enterFromClasses = (enterFrom ?? '').split(' ');
    const enterToClasses = (enterTo ?? '').split(' ');

    return (
        <CSSTransition
            unmountOnExit
            in
            addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false)
            }}
            onEnter={(node) => {
                node.classList.add(...enterClasses, ...enterFromClasses)
            }}
            onEntering={(node) => {
                node.classList.remove(...enterFromClasses);
                node.classList.add(...enterToClasses)
            }}
            onEntered={(node) => {
                node.classList.remove(...enterToClasses, ...enterClasses)
            }}
        >
            {children}
        </CSSTransition>
    )
};